import { formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { useAppInfo } from "@src/appV2/lib/utils/capacitor/useAppInfo";
import { isSolveUnpaidBreaksFeatureEnabled } from "@src/appV2/Shifts/utils/isSolveUnpaidBreaksFeatureEnabled";
import { type ReactElement } from "react";

import { type ShiftStateDependencies, type ShiftStateMetadata } from "../types";

interface Props {
  shiftStateMetadata: ShiftStateMetadata;
  shiftStateDependencies: ShiftStateDependencies;
}

export function ClockOutContent(props: Props): ReactElement {
  const {
    shiftStateMetadata: {
      isShiftOver,
      isDigitalSignatureEnabled,
      displayTimeSheetSummary,
      solveUnpaidBreaksRolloutStageWithTargetingConfig,
    },
    shiftStateDependencies,
  } = props;

  const { appInfo } = useAppInfo();

  const formattedClockOutTime = formatTime(shiftStateDependencies.end, {
    timeZone: shiftStateDependencies.facility.tmz,
  });
  const isSolveUnpaidBreaksEnabled =
    isDigitalSignatureEnabled &&
    displayTimeSheetSummary &&
    isSolveUnpaidBreaksFeatureEnabled({
      solveUnpaidBreaksRolloutStageWithTargetingConfig,
      appInfo,
    });

  return (
    <>
      <Text>
        Please try to clock out by <b>{formattedClockOutTime}</b>.{" "}
        {!isShiftOver &&
          isSolveUnpaidBreaksEnabled &&
          "You will be able to add your break times after clock-out, while submitting your timesheet."}
      </Text>
      {isShiftOver ? (
        <>
          <Text>It seems like you&apos;re running late.</Text>
          {displayTimeSheetSummary && (
            <Text>Don&apos;t worry - you can edit your timesheet at the end.</Text>
          )}
        </>
      ) : null}
    </>
  );
}
