import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";
import { useCountdownTimer } from "@src/appV2/lib/utils/useCountdownTimer";
import { intervalToDuration, isAfter, parseISO, setMilliseconds, setSeconds } from "date-fns";
import pluralize from "pluralize";
import { useEffect } from "react";

export interface ClockOutDialogProps {
  modalState: UseModalState;
  shiftEnd: string;
  onContinue: () => void;
  onCancel: () => void;
}

const REGULAR_CLOCK_OUT_LEFTOVER_DURATION_IN_HOURS = 1;

function getDurationUntilShiftEnd(shiftEnd: string) {
  const now = setMilliseconds(setSeconds(new Date(), 0), 0);
  const end = setMilliseconds(setSeconds(parseISO(shiftEnd), 0), 0);

  if (isAfter(now, end)) {
    return { hours: 0, minutes: 0 };
  }

  const {
    days,
    hours = 0,
    minutes = 0,
  } = intervalToDuration({
    start: now,
    end,
  });
  let totalHours = hours;

  if (isDefined(days) && days > 0) {
    // disregarding the rest of the properties from intervalToDuration
    // because this UI will never show when we are more than a day away from shift end
    totalHours += days * 24;
  }

  return {
    hours: totalHours,
    minutes,
  };
}

export function ClockOutDialog(props: ClockOutDialogProps): JSX.Element {
  const { modalState, shiftEnd, onContinue, onCancel } = props;

  const { secondsLeft, startTimer } = useCountdownTimer({
    durationInSeconds: 10,
  });
  const timeUntilShiftEnd = getDurationUntilShiftEnd(shiftEnd);

  useEffect(() => {
    if (modalState.modalIsOpen) {
      startTimer();
    }
  }, [modalState.modalIsOpen, startTimer]);

  const isTooEarlyForClockOut =
    timeUntilShiftEnd.hours >= REGULAR_CLOCK_OUT_LEFTOVER_DURATION_IN_HOURS;
  const timeUntilEnd = `${timeUntilShiftEnd.hours}:${
    timeUntilShiftEnd.minutes < 10 ? `0${timeUntilShiftEnd.minutes}` : timeUntilShiftEnd.minutes
  }`;

  return (
    <CommonDialog
      modalState={modalState}
      title="Are you sure you want to clock out?"
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            disabled={isTooEarlyForClockOut && secondsLeft > 0}
            onClick={onContinue}
          >
            Continue
          </Button>
          <Button fullWidth onClick={onCancel}>
            Go Back
          </Button>
        </>
      }
    >
      {isTooEarlyForClockOut && (
        <>
          <Text color="red" marginBottom={2}>
            You&apos;re about to clock out {timeUntilEnd} hours before the scheduled end of your
            shift.
          </Text>
          <Text marginBottom={2}>
            Please note: you no longer need to record break times during your shift. Instead, you
            can edit them when submitting your timesheet.
          </Text>
          <Text>
            {secondsLeft > 0
              ? `You can proceed in ${secondsLeft} ${pluralize("second", secondsLeft)}.`
              : "You can now proceed to clock out."}
          </Text>
        </>
      )}
    </CommonDialog>
  );
}
