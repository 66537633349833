import { formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isAfter, parseISO, subMinutes } from "date-fns";
import { type ReactElement } from "react";

import { EARLY_CLOCK_IN_ENABLE_LIMIT_IN_MINUTES } from "../../../../../app/hcpShifts/constants";
import { type ShiftStateDependencies, type ShiftStateMetadata } from "../types";

interface Props {
  shiftStateMetadata: ShiftStateMetadata;
  shiftStateDependencies: ShiftStateDependencies;
}

export function ClockInContent(props: Props): ReactElement {
  const {
    shiftStateMetadata: { isLate, displayTimeSheetSummary, isEarlyClockInEnabled },
    shiftStateDependencies,
  } = props;

  const formattedClockInTime = formatTime(shiftStateDependencies.start, {
    timeZone: shiftStateDependencies.facility.tmz,
  });
  const isClockInButtonEnabled = isAfter(
    new Date(),
    subMinutes(parseISO(shiftStateDependencies.start), EARLY_CLOCK_IN_ENABLE_LIMIT_IN_MINUTES)
  );

  return (
    <>
      {isEarlyClockInEnabled ? (
        <Text>
          Please try to clock in by <b>{formattedClockInTime}</b>.{" "}
          {!isLate &&
            !isClockInButtonEnabled &&
            "Clock in will be enabled 10 minutes before shift starts."}
        </Text>
      ) : (
        <Text>
          This facility does not allow early clock-ins. Please wait until the shift&apos;s posted
          start time ({formattedClockInTime}) to clock in!
        </Text>
      )}
      {isLate && (
        <>
          <Text>It seems like you&apos;re running late.</Text>
          {displayTimeSheetSummary ? (
            <Text>Don&apos;t worry - you can edit your timesheet at the end.</Text>
          ) : null}
        </>
      )}
    </>
  );
}
